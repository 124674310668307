import React, {Component} from 'react';
import TSheetsItem from './TSheetsItem';
import {Card, CardText} from "reactstrap";

class TSheetsGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            result : props.data
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            result : nextProps.data
        }
    }

    render() {
        let items = this.state.result;
        return this.state.result.length > 0 ? (
            <div style={{overflowY: 'scroll', height: '75vh'}}>
                {items.map((val, idx) => (
                    <TSheetsItem item={val} key={idx} />
                ))}
            </div>
        ) : (
          <Card body inverse color="danger">
              <CardText cssModule={{color: "white"}}>There are no Timesheets for this employee on this day</CardText>
          </Card>
        );
    }
}

export default TSheetsGroup;