import React, {Component} from 'react';
import FleetItem from './FleetItem';
import {Card, CardText} from "reactstrap";

class FleetGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            result : props.data
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            result: nextProps.data
        };
    }

    render() {
        let items = this.state.result;
        items = items.sort((a,b) => {
            return new Date(a["ArrivalTime"]) - new Date(b["ArrivalTime"]);
        });
        return items.length > 0 ? (
            <div style={{overflowY: 'scroll', height: '75vh', overflowX: 'hidden'}}>
                {items.map((value, idx) =>(
                    <FleetItem record={value} key={idx} />
                ))}
            </div>
        ) :(
            <Card body inverse color="danger">
                <CardText>There is no fleet activity for this employee on this day</CardText>
            </Card>
        )
    }
}

export default FleetGroup;