import React, { Component } from 'react';
import {Navbar, NavbarBrand} from "reactstrap";
import {SignOut} from 'aws-amplify-react';

import "react-datepicker/dist/react-datepicker.css";

class Header extends Component {

    render() {
        return (
            <div>
                <Navbar color="light" light >
                    <NavbarBrand>T-Sheets Comparer</NavbarBrand>
                    <SignOut ></SignOut>
                </Navbar>
            </div>
        );
    }
}

export default Header;