
const amplifyConfig = {
    Auth: {
        // identityPoolId: "us-east-1:189ead8a-e1d0-4bc3-a7b1-48e157302a0f",
        region: "us-east-1",
        userPoolId: "us-east-1_Whwv6zkTz",
        mandatorySignIn: true,
        userPoolWebClientId: "6hq7q57mjd4g6hik6hrahtfljo"
    },
    API: {
        endpoints: [
            {
                name: "TsheetsData",
                endpoint: "https://ioq7dv5d5m.execute-api.us-east-1.amazonaws.com/Prod"
            }
        ]
    }
}


export default amplifyConfig;