import React, {Component} from "react";
import {Card, CardText, Col, Container, Form, FormGroup, Label, Row} from "reactstrap";
import DatePicker from "react-datepicker/es";
import FleetGroup from "./FleetGroup";
import TSheetsGroup from "./TSheetsGroup";
import { Auth, API } from 'aws-amplify';


class Comparer extends Component {

    constructor(props){
        super(props);
        this.yesterday = new Date();
        this.yesterday.setDate(this.yesterday.getDate() - 1);
        this.yesterday.setHours(0,0,0,0);
        this.state = {
            day: this.yesterday,
            employee: "",
            employees: [],
            data: {},
            loading: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.changeEmployee = this.changeEmployee.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        let apiName = "TsheetsData";
        let path = "/listEmployees";
        Auth.currentSession()
            .then(session => {
                let init = {
                    headers: {
                        Authorization: session.getIdToken().getJwtToken()
                    }
                };
                API.get(apiName, path, init)
                    .then(response =>{
                        // console.log(response);
                        this.setState({
                            employees: response
                        })
                    }).catch(err => {
                        console.log(err);
                    })
                });
    }

    getData(opts) {
        this.setState({
            employee : opts.employee ? opts.employee : this.state.employee,
            day : opts.day ? opts.day : this.state.day,
            loading: true
        });
        let apiName = "TsheetsData";
        let path = "/data";
        Auth.currentSession()
            .then( session => {
                let init = {
                    headers: {
                        Authorization: session.getIdToken().getJwtToken()
                    },
                    queryStringParameters: {
                        employee : this.state.employee,
                        day : this.state.day.toISOString()
                    }
                };
                API.get(apiName, path, init)
                    .then(response => {
                        this.setState({
                            data : response,
                            loading: false
                        });
                    }).catch(err => console.log(err));
            }).catch(err => console.log(err));

    }

    handleChange(date) {
        this.getData({day : date});
    }

    changeEmployee(e) {
        let employee = e.target.value;
        this.getData({employee : employee});
    }

    render() {

        return (
           <Container fluid>
               <Row className="justify-content-center">
                   <Col xs="auto">
                       <Form inline>
                           <FormGroup className="p-2">
                               <Label className="mr-1">Date:</Label>
                               <DatePicker
                                   selected={this.state.day}
                                   onChange={this.handleChange}
                                   withPortal
                                   maxDate={this.yesterday}
                               />
                           </FormGroup>
                           <FormGroup className="p-2">
                               <Label className="mr-1">Employee </Label>
                               <select  value={this.state.employee} onChange={this.changeEmployee}>
                                   <option value="">Select an Employee</option>
                                   {this.state.employees.map((employee, indx) => (
                                       <option key={indx} value={employee}>{employee}</option>
                                   ))}
                               </select>
                           </FormGroup>
                       </Form>
                   </Col>
               </Row>
               {this.state.loading ? (
                    <Card body inverse={this.state.employee !== ""} outline={this.state.employee === ""} color={this.state.employee === "" ? "warning" : "success"}>
                        <CardText>{this.state.employee === "" ? "Please pick an employee to get started" : "Loading..."}</CardText>
                    </Card>
               ) : (
                   <Row>
                       <Col md="6">
                           <h2>Fleet Data</h2>
                           <FleetGroup data={this.state.data.fleet} />
                       </Col>
                       <Col md="6">
                       <h2>TSheets</h2>
                       <TSheetsGroup data={this.state.data.tsheets} />
                       </Col>
                   </Row>
               )}
           </Container>
        )
    }
}

export default Comparer;
