import React, {Component} from 'react';
import {Button, Card, Col, Row} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class FleetItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible : true
        };
        this.hideItem = this.hideItem.bind(this);
    }


    hideItem(){
        this.setState({
            visible: false
        });
    }

    render() {
        return (
          <div>
              {this.state.visible &&
              <Card>
                  <Row>
                      <Col className="justify-content-center" md="1" cssModule={{display:'table-cell'}}>
                          <Button onClick={this.hideItem} color="warning" cssModule={{verticalAlign: 'center'}}>
                              <FontAwesomeIcon icon="times-circle"/>
                          </Button>
                      </Col>
                      <Col>
                          Start: {this.props.record["Arrival Time"]} || End:{" "}
                          {this.props.record["Departure Time"]}
                          <br/>
                          Location: {this.props.record["Stop Geofence"]}
                          <br/>
                          Address: {this.props.record["Stop Location"]}
                      </Col>
                  </Row>
              </Card>
              }
          </div>
        );
    }
}

export default FleetItem;