import React, {Component} from 'react';
import {Card} from 'reactstrap';

class TSheetsItem extends Component {
    render() {
        let start = new Date(this.props.item["start"]);
        let end = new Date(this.props.item["end"]);
        let job = this.props.item["job"]["name"];
        let si = this.props.item["fields"]["Service Item"];
        return (
            <Card>
                Start: {start.toLocaleTimeString('en-US', {timeZone: 'America/New_York'})} || End: {end.toLocaleTimeString('en-US', {timeZone: 'America/New_York'})}
                <br/>
                Job: {job}
                <br/>
                Service Item: {si}
            </Card>
        );
    }
}

export default TSheetsItem;