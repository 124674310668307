import React, { Component } from 'react';
import Header from "./components/Header";
import Comparer from "./components/Comparer";
import Amplify from 'aws-amplify';
import {withAuthenticator} from 'aws-amplify-react';
import amplifyConfig from './amplify-config';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

Amplify.configure(amplifyConfig);
library.add(faTimesCircle);

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header></Header>
        <Comparer></Comparer>
      </div>
    );
  }
}

export default withAuthenticator(App);
